.appbar {
  width: 100%;
  height: 1.26rem;
  background-color: #fff;
  padding: 0 1.16rem;
  display: flex;
  align-items: center;

  &-logo {
    width: 1.8834rem;
    height: 0.52rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-route {
    margin-right: 1.86rem;
    width: 3.8rem;
    height: 0.74rem;
    display: flex;
    align-items: center;
    padding: 0.06rem 0.1rem;
    border-radius: 0.38rem;
    background-color: #f0f0f0;

    &-item {
      width: 1.8rem;
      height: 0.62rem;
      border-radius: 0.38rem;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.24rem;
      line-height: 0.33rem;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;

      &.active {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }

  &-link-item {
    display: inline-block;
    width: 0.68rem;
    height: 0.68rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    &:nth-child(1) {
      margin-right: 0.46rem;
    }
  }
}
