.home {
  width: 100%;

  &-banner {
    width: 100%;
    height: 7.49rem;
    position: relative;

    &-background {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-people {
      display: block;
      width: 11.7rem;
      height: 8.05rem;
      position: absolute;
      right: -1rem;
      bottom: 0;
    }

    &-image {
      display: block;
      width: 4.68rem;
      position: absolute;
      left: 1.12rem;
      top: 2.18rem;
    }

    &-connect {
      display: block;
      width: 3.99rem;
      position: absolute;
      left: 1.12rem;
      bottom: 2.03rem;
      cursor: pointer;
    }

    &-scroll-1 {
      display: block;
      width: 110%;
      position: absolute;
      left: -5%;
      bottom: -0.87rem;
    }

    &-scroll-2 {
      display: block;
      width: 110%;
      position: absolute;
      left: -5%;
      bottom: -1.08rem;
    }
  }

  &-story {
    margin-top: 1.75rem;
    width: 100%;
    padding: 0 1.16rem;
    display: flex;
    margin-bottom: 1.07rem;

    &-icon {
      margin-left: 0.66rem;
      display: block;
      width: 3rem;
      height: 6rem;
      margin-right: 1.285rem;
    }

    &-contents {
      flex: 1;

      &-title {
        font-size: 0.7rem;
        line-height: 0.96rem;
        color: #000000;
        font-weight: bolder;
        margin-bottom: 0.24rem;
      }

      &-describe {
        font-size: 0.24rem;
        line-height: 0.33rem;
        color: #000000;
        margin-bottom: 0.6rem;
        width: 6.8rem;
      }

      &-button {
        width: 2.98rem;
        height: 0.82rem;
        border-radius: 0.414rem;
        background-color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 0.24rem;
        line-height: 0.33rem;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  &-welcome {
    width: 100%;
    padding: 0 1.16rem;

    &-wrap {
      width: 100%;
      height: 4.84rem;
      background-image: url('../../assets/welcome_background.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      padding-left: 0.84rem;
      padding-top: 0.48rem;
    }

    &-icon {
      width: 4.3089rem;
      height: 5.6463rem;
      position: absolute;
      right: 0.125rem;
      bottom: 0;
    }

    &-title {
      font-size: 0.7rem;
      line-height: 0.96rem;
      color: #ffffff;
      font-weight: bolder;
      margin-bottom: 0.07rem;
    }

    &-describe {
      font-size: 0.24rem;
      line-height: 0.33rem;
      color: #ffffff;
      margin-bottom: 1.2rem;
    }

    &-button {
      width: 3.98rem;
      height: 0.82rem;
      border-radius: 0.414rem;
      background-color: #974c4c;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 0.24rem;
      line-height: 0.33rem;
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &-faq {
    margin-top: 1.63rem;
    margin-bottom: 2.15rem;
    width: 100%;
    padding: 0 1.16rem;
    display: flex;

    &-icon {
      width: 6.58rem;
      height: 6.58rem;
      margin-right: 1.23rem;
    }

    &-questions {
      flex: 1;
      padding-top: 0.28rem;

      &-title {
        font-size: 0.7rem;
        line-height: 0.96rem;
        color: #000000;
        font-weight: bolder;
        margin-bottom: 0.35rem;
      }

      &-item {
        width: 100%;
        height: 0.72rem;
        border-radius: 16px;
        padding: 0.2rem 0.84rem 0.2rem 0.24rem;
        background-color: #f3f3f3;
        position: relative;
        cursor: pointer;
        margin-bottom: 0.12rem;

        &-title {
          font-size: 0.24rem;
          line-height: 0.33rem;
          color: #000000;
        }

        &-icon {
          position: absolute;
          right: 0.24rem;
          top: 0.24rem;
          width: 0.24rem;
          height: 0.24rem;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-buy {
        margin-top: 0.44rem;
        width: 2.98rem;
        height: 0.82rem;
        border-radius: 0.414rem;
        background-color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 0.24rem;
        line-height: 0.33rem;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
