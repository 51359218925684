.gallery {
  width: 100%;
  display: flex;
  padding: 0 0 0 1.12rem;

  &-filter {
    width: 3.67rem;
    margin-right: 0.2rem;

    &-title {
      font-size: 0.4rem;
      line-height: 0.55rem;
      font-weight: bolder;
      color: #000000;
      margin-bottom: 0.12rem;
    }

    &-arrow {
      display: block;
      width: 0.24rem;
      height: 0.24rem;
    }

    &-selector {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &-name {
        font-size: 0.24rem;
        line-height: 0.33rem;
        color: #000000;
        opacity: 0.6;
      }
    }
  }

  &-contents {
    flex: 1;
    padding: 0.24rem 0.4rem;
    border-top-left-radius: 0.15rem;
    background-color: #fafafa;

    &-nfts {
      display: grid;
      grid-template-columns: repeat(auto-fill, 2.36rem);
      grid-template-rows: repeat(auto-fill, 3.04rem);
      grid-gap: 0.22rem;

      &-item {
        width: 2.36rem;
        height: 3.04rem;
        display: flex;
        flex-direction: column;

        img {
          width: 2.36rem;
          height: 2.36rem;
          object-fit: cover;
          border-radius: 0.24rem;
        }

        &-infos {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &-name {
            font-size: 0.18rem;
            line-height: 0.25rem;
            font-weight: bolder;
          }
        }
      }
    }
  }

  &-loader {
    font-size: 0.24rem;
    text-align: center;
  }
}
