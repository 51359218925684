.footer {
  width: 100vw;
  height: 3.5rem;
  background-color: #191919;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-logo {
    width: 1.8834rem;
    height: 0.52rem;
    margin-bottom: 0.42rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      display: inline-block;
      font-size: 0.24rem;
      line-height: 0.33rem;
      color: #ffffff;
      margin: 0 0.24rem;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
